<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_75_162)">
            <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#F0F0F0"/>
            <path d="M2.48063 4.69416C1.53802 5.92055 0.827251 7.33406 0.413391 8.86964H6.65611L2.48063 4.69416Z" fill="#0052B4"/>
            <path d="M23.5866 8.86964C23.1728 7.33411 22.4619 5.92059 21.5194 4.6942L17.344 8.86964H23.5866Z" fill="#0052B4"/>
            <path d="M0.413391 15.1305C0.827297 16.666 1.53806 18.0795 2.48063 19.3059L6.65597 15.1305H0.413391Z" fill="#0052B4"/>
            <path d="M19.3059 2.48067C18.0795 1.53806 16.666 0.827297 15.1304 0.413391V6.65606L19.3059 2.48067Z" fill="#0052B4"/>
            <path d="M4.69415 21.5193C5.92054 22.4619 7.33406 23.1727 8.86959 23.5866V17.344L4.69415 21.5193Z" fill="#0052B4"/>
            <path d="M8.86954 0.413391C7.33401 0.827297 5.9205 1.53806 4.69415 2.48063L8.86954 6.65602V0.413391Z" fill="#0052B4"/>
            <path d="M15.1304 23.5866C16.666 23.1727 18.0795 22.4619 19.3058 21.5194L15.1304 17.344V23.5866Z" fill="#0052B4"/>
            <path d="M17.344 15.1305L21.5194 19.3059C22.4619 18.0796 23.1728 16.666 23.5866 15.1305H17.344Z" fill="#0052B4"/>
            <path d="M23.8984 10.4348H13.5653H13.5653V0.101578C13.0529 0.034875 12.5305 0 12 0C11.4694 0 10.9471 0.034875 10.4348 0.101578V10.4347V10.4347H0.101578C0.034875 10.9471 0 11.4695 0 12C0 12.5306 0.034875 13.0529 0.101578 13.5652H10.4347H10.4347V23.8984C10.9471 23.9651 11.4694 24 12 24C12.5305 24 13.0529 23.9652 13.5652 23.8984V13.5653V13.5653H23.8984C23.9651 13.0529 24 12.5306 24 12C24 11.4695 23.9651 10.9471 23.8984 10.4348Z" fill="#D80027"/>
            <path d="M15.1304 15.1305L20.4852 20.4853C20.7315 20.2391 20.9665 19.9817 21.1906 19.7149L16.6062 15.1305H15.1304V15.1305Z" fill="#D80027"/>
            <path d="M8.86957 15.1305H8.86947L3.51471 20.4853C3.7609 20.7315 4.01829 20.9665 4.2851 21.1906L8.86957 16.6061V15.1305Z" fill="#D80027"/>
            <path d="M8.86957 8.86964V8.86955L3.51476 3.51469C3.26848 3.76087 3.03354 4.01826 2.80939 4.28508L7.3939 8.86959H8.86957V8.86964Z" fill="#D80027"/>
            <path d="M15.1304 8.86964L20.4853 3.51473C20.2391 3.26845 19.9817 3.03352 19.7149 2.80941L15.1304 7.39392V8.86964Z" fill="#D80027"/>
        </g>
        <defs>
            <clipPath id="clip0_75_162">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>


</template>

<script>
export default {
    name: "UKFlag"
}
</script>

<style scoped>

</style>